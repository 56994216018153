<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar
        :titleValue="titleValue"
        :titleItems="titleItems"
        @onTitleItem="onTitleItem"
        :showBackButton="false"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton"
        :dropdownMenuItems="dropdownMenuItems"
        @onDropdownMenuItem="onDropdownMenuItem"
        :tabs="toolbarTabs"
        @onTab="onToolbarTab"
        @onButtonMenuItem="onButtonMenuItem"
      ></app-view-toolbar>
    </v-container>
    <v-container class="px-6">
      <v-tabs-items v-model="toolbarTab">
        <v-tab-item>
          Tab content A
        </v-tab-item>
        <v-tab-item>
          Tab content B
        </v-tab-item>
        <v-tab-item>
          Tab content C
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <select-list
      selectLabel="Välj föreställning"
      selectPlaceholder="Välj snabbt..."
      :selectOptions="listData.items"
      selectOptionValue="id"
      selectOptionText="id"
      :listHeaders="listData.headers"
      v-model="selectedShows">
    </select-list>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import ViewToolbar from '../components/ViewToolbar.vue';
import SelectList from '../components/SelectList.vue';

export default {
  name: 'Customer',
  components: {
    'app-view-toolbar': ViewToolbar,
    'select-list': SelectList,
  },
  // props: {
  //   id: {
  //     type: Number,
  //     required: true,
  //   }
  // },
  setup() {
    const dataLoaded = ref(true);

    const titleItems = [
      {
        text: 'BMW',
        value: '1',
      },
      {
        text: 'Cadillac',
        value: '2',
      },
      {
        text: 'Chevrolet',
        value: '3',
      },
      {
        text: 'Mercedes',
        value: '4',
      },
      {
        text: 'Honda',
        value: '5',
      },
      {
        text: 'Pontiac',
        value: '6',
      },
      {
        text: 'Saab',
        value: '7',
      },
    ];

    const titleValue = '3';

    const onToolbarButton = button => {
      console.log('onToolbarButton', button);
    }

    const onDropdownMenuItem = item => {
      console.log('onDropdownMenuItem', item);
    }

    const onTitleItem = item => {
      console.log('onTitleItem', item);
    }

    const onButtonMenuItem = item => {
      console.log('onButtonMenuItem', item);
    }

    const doOnMounted = () => {
      // callApi({
      //   method: 'get',
      //   path: '/cms/customers/' + id,
      // })
      //   .then((apiResponse) => {
      //     onData('mounted', apiResponse);
      //   })
      //   .catch((err) => {
      //     return err;
      //   });
    };
    [
      { id: 1, name: 'Tårtgeneralen 21.00' },
      { id: 2, name: 'Fast 2 21.00' },
    ]

    const selectedShows = ref([]);

    const listData = reactive({
      headers: [
        { text: "Id", value: "id" },
        { text: "Namn", value: "name" },
        { text: "Plats", value: "location" },
      ],
      items: [
        { id: 1, location: 'Älmhult', name: 'Tårtgeneralen 21.00' },
        { id: 2, location: 'Älmhult', name: 'Fast 2 21.00' },
        { id: 3, location: 'Älmhult', name: 'Stuart Littke 23.00' },
        { id: 4, location: 'Älmhult', name: 'Bolibompa 18.00' },
      ],
    });

    const toolbarTabs = [
      {
        label: 'tabA',
        text: 'Tab A',
      },
      {
        label: 'tabB',
        text: 'Tab B',
      }
    ];

    const toolbarTab = ref(toolbarTabs[0]);

    const onToolbarTab = tab => {
      toolbarTab.value = tab;
    }

    const toolbarButtons = [
      {
        type: 'menu',
        label: 'swapEvent',
        icon: 'swap-horizontal',
        name: 'Byt event',
        menuItems: [
          {
            type: 'button',
            label: '1',
            name: 'BMW',
          },
          {
            type: 'button',
            label: '2',
            name: 'Honda',
          },
        ],
      }
    ];

    const dropdownMenuItems = [
      {
        type: 'button',
        label: 'editEvent',
        name: 'Redigera event',
        icon: 'pencil-outline'
      },
      {
        type: 'button',
        label: 'saveEvent',
        name: 'Nytt event',
        icon: 'plus'
      },
      {
        type: 'subheader',
        name: 'Övrigt',
      },
      {
        type: 'button',
        label: 'stages',
        name: 'Scener',
      },
    ];

    onMounted(doOnMounted);

    return {
      titleItems,
      titleValue,
      onTitleItem,
      toolbarButtons,
      onToolbarButton,
      dataLoaded,
      dropdownMenuItems,
      onDropdownMenuItem,
      onToolbarTab,
      toolbarTabs,
      toolbarTab,
      onButtonMenuItem,

      selectedShows,
      listData,
    }
  },
};
</script>

<style>

</style>